import React, { useEffect, useRef } from "react"
import { graphql } from "gatsby"
import classnames from "classnames/bind"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import CustomEase from "../../vendors/gsap/CustomEase.min"
// Data
import useProjectData from "../../hook/projectpage-data"
// Component
import {
  FullSizeBlock,
  ColumSizeBlock,
  ColumSizeComingSoonBlock,
} from "../../components/block"
// Style
import marqueeStyle from "../../style/components/marquee.module.scss"

export default function HomepageProjects(props) {
  // - Data
  const { edges } = useProjectData()

  // fadeInRef Array
  const fadeInRef = useRef([])
  fadeInRef.current = []

  // - Animate
  CustomEase.create("cubic", "0.77, 0, 0.18, 1")
  useEffect(() => {
    if (typeof window === "undefined" || !window.document) {
      console.log(`windows error`)
    }

    // FadeIn
    fadeInRef.current.forEach((el, index) => {
      gsap
        .timeline({
          ease: "cubic",
          duration: 0.2,
          scrollTrigger: {
            id: `fadeIn-${index + 1}`,
            trigger: el,
            start: "top bottom",
            toggleActions: "play none none reverse",
            markers: false,
          },
        })
        .from(el, {
          autoAlpha: 0,
          y: 40,
        })
        .to(el, {
          autoAlpha: 1,
          y: 0,
        })

      //* 需要重新refresh，不然受到pageTransition的影響，scrollTrigger star end的位置會跑掉
      ScrollTrigger.refresh()
    })
    return () => {
      fadeInRef.current.forEach((el, index) => {
        ScrollTrigger.getById(`fadeIn-${index + 1}`).kill()
      })
    }
  }, [])

  // Add children to Ref Array
  const addToRefs = el => {
    if (el && !fadeInRef.current.includes(el)) {
      fadeInRef.current.push(el)
    }
  }

  //* NOTE: projects/pagenamehere 的名稱需與prismic database的project uid相同，連結位置才會正確

  return pug`
    section#HomepageProjects.page-section.relative(className=classnames(props.className))
      div
        each items,index in edges
          if (0 <= index && index <=1)
            - const document = items.node.data
            FullSizeBlock(
              key=items.node.id
              index=index
              link="/projects/"+items.node.uid
              title=document.project_name.text
              image_url=document.hero_image.fluid
              image_alt=document.hero_image.alt
              cate=document.type.text
              description=document.project_description.text
              ref=addToRefs
            )
        div.flex.flex-wrap.pb-48
          each items,index in edges
            if (2 <= index && index <=3)
              - const document = items.node.data
              ColumSizeBlock(
                key=items.node.id
                index=index
                link="/projects/"+items.node.uid
                title=document.project_name.text
                image_url=document.hero_image.fluid
                image_alt=document.hero_image.alt
                cate=document.type.text
                description=document.project_description.text
                ref=addToRefs
              )
            //-* Project Coming Soon
            // if (index == 3 )
            //   - const document = items.node.data
            //   ColumSizeComingSoonBlock(
            //     key=items.node.id
            //     index=index
            //     link="/projects/"+items.node.uid
            //     title=document.project_name.text
            //     image_url=document.hero_image.fluid
            //     image_alt=document.hero_image.alt
            //     cate=document.type.text
            //     description=document.project_description.text
            //     ref=addToRefs
            //   )

  `
}
