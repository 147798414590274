import React, { useEffect } from "react"
import { Link } from "gatsby"
import classnames from "classnames/bind"
import { motion } from "framer-motion"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

// Component
import { MarqueeTitle, MarqueeTitleThree } from "../../components/marquee"
import { HeaderLinkItem } from "../../layouts/header"
import {
  OneColumnOnlyHeading,
  OneColumnContent,
} from "../../components/project/one-column"
import { FullExperienceBlock } from "../../components/about/experience-block"
import { ExprList } from "../../components/list"

// Data
import useAboutpageData from "../../hook/aboutpage-data"

// Style
import listStyle from "../../style/components/list.module.scss"
import buttonStyle from "../../style/components/button.module.scss"

const HomepageExperience = React.forwardRef((props, ref) => {
  const {
    experience_title,
    experience_group,
    awards_group,
    awards_title,
  } = useAboutpageData()

  const HomeExperienceData = {
    heading: "sneak peek",
    awards: awards_title.text,
    awards_group: awards_group,
    experience: experience_title.text,
    experience_group: experience_group,
  }

  useEffect(() => {
    if (typeof window === "undefined" || !window.document) {
      console.log(`windows error`)
    }

    gsap.timeline({
      ease: "none",
      scrollTrigger: {
        id: "layoutWrapperBgChange",
        // scroller: ".scrollContainer",
        trigger: "#HomepageExpr",
        start: "top center",
        end: "bottom top",
        markers: false,
        toggleClass: {
          targets: "#layoutWrapper",
          className: "bg-dark",
        },
      },
    })

    //* 將Marquee轉換成Array，在將上面一行切分出來進行動畫更改
    const MarqueeTitle = document.querySelectorAll(".MarqueeTitleText")
    const MarqueeTitleArray = Array.apply(null, MarqueeTitle)
    const MarqueeTitleTextFirst = MarqueeTitleArray.slice(0, 4)

    gsap.timeline({
      ease: "none",
      scrollTrigger: {
        id: "marqueeColorChange",
        trigger: "#HomepageExpr",
        start: "top center",
        end: "bottom top",
        markers: false,
        toggleClass: {
          targets: MarqueeTitleTextFirst,
          className: "txt-hasShadow-white-white",
        },
      },
    })

    //* 需要重新refresh，不然受到pageTransition的影響，scrollTrigger star end的位置會跑掉
    ScrollTrigger.refresh()

    return () => {
      if (ScrollTrigger.getById("layoutWrapperBgChange")) {
        ScrollTrigger.getById("layoutWrapperBgChange").kill()
      }
      if (ScrollTrigger.getById("marqueeColorChange")) {
        ScrollTrigger.getById("marqueeColorChange").kill()
      }
    }
  }, [])

  // TODO: 調整header跟social的darkmode

  return pug`
    section#HomepageExpr.page-section.relative.pb-40(className=classnames(props.className))
      MarqueeTitle(title="Get to know Me Better").relative
      div.flex.flex-wrap.content-center.pt-20.darkMode(ref=ref)
        // Experience
        div.flex.items-center.justify-center.mt-20
          div.flex.items-start.justify-start.flex-wrap.col-12.px-0
            h5.text-3x.text-white.font-bold.uppercase.mb-6=HomeExperienceData.experience
            each item,index in HomeExperienceData.experience_group
              FullExperienceBlock(name=item.name.text date=item.date_and_local.text title=item.title.text bgColor="bg-primary-main" icon="icon" fluid=item.icon.fluid)
        // Awards
        div.flex.items-center.justify-center.mt-20
          div.flex.items-start.justify-start.flex-wrap.col-12.px-0
            h5.text-3x.text-white.font-bold.uppercase.mb-6=awards_title.text
            each item,index in awards_group
              FullExperienceBlock(name=item.name.text date=item.date_and_local.text title=item.title.text bgColor="bg-secondary-main" icon="trophy")
        div.flex.justify-center.w-full.mt-16
          HeaderLinkItem(to="/about")
            div(className=classnames(buttonStyle.btn, buttonStyle.btn_lg, buttonStyle.btnShadow_white))
              span Discover More
  `
})

export default HomepageExperience
