import React, { useEffect, useRef, useState } from "react"
import Link from "gatsby-plugin-transition-link"
import classnames from "classnames/bind"
import { motion } from "framer-motion"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

// Component
import { MarqueeTitle } from "./marquee"

// Data
import useAboutpageData from "../hook/aboutpage-data"

// Style
import listStyle from "../style/components/list.module.scss"
import buttonStyle from "../style/components/button.module.scss"

gsap.registerPlugin(ScrollTrigger)

const ExprListItem = props => pug`
  ul.mb-10
    li.h5.capitalize.mb-1=props.name
    li.text-base.text-gray-200.font-semibold.uppercase.mb-1=props.title
    li.txt-caption.text-gray-300=props.date
`

const ExprList = props => pug`
  div.col-12.col-md-6(className=listStyle.exprlist_wrapper)
    div.r-md(className=listStyle.exprlist_content)
      div.mb-12
        h3=props.heading
      div
        each item,index in props.items
          ExprListItem(name=item.name.text title=item.title.text date=item.date_and_local?item.date_and_local.text:"")
`
export { ExprListItem, ExprList }
