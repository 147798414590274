import React, { useRef, useEffect } from "react"
import classnames from "classnames/bind"

// Component
import Landing from "../../components/landing"

// Style
import LandingStyle from "../../style/components/landing.module.scss"

export default function HomepageLanding(props) {
  return pug`
    section(className=LandingStyle.landing_wrapper)
      div.flex.justify-center.items-center.flex-col
        div.flex.justify-start.flex-col.col-12.col-lg-10.px-0(className=classnames('md:justify-between','md:flex-row'))
          Landing

  `
}
