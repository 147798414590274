import React, { useEffect, useState, useRef, useCallback } from "react"
import classnames from "classnames/bind"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { useLocation } from "@reach/router"
// Component
import LayoutWrapper from "../layouts/wrapper"
import ScrollWrapper from "../layouts/scroll-wrapper"
import Metadata from "../layouts/metadata"
import Header from "../layouts/header"
import { MainContainer } from "../layouts/container"
import Footer from "../layouts/footer"
import Social from "../layouts/social"
// section
import HomepageProjects from "../section/homepage/homepage-projects"
import HomepageExperience from "../section/homepage/homepage-experience"
import HomepageLanding from "../section/homepage/homepage-landing"

const Headline = props => {
  // - Data
  const { pathname } = useLocation()

  // fadeInRef Array
  const fadeInRef = useRef([])
  fadeInRef.current = []

  // Add children to Ref Array
  const addToRefs = el => {
    if (el && !fadeInRef.current.includes(el)) {
      fadeInRef.current.push(el)
    }
  }

  // - Dark mode
  const darkModeRef = useRef(null)
  const [isDark, setIsDark] = useState(false)
  function toggleDarkMode(isActive) {
    setIsDark(isActive)
  }

  useEffect(() => {
    gsap.timeline({
      scrollTrigger: {
        id: "elementDarkMode",
        trigger: "#HomepageExpr",
        start: "top center",
        end: "bottom+=400 top",
        markers: false,
        onToggle: ({ isActive }) => toggleDarkMode(isActive),
      },
    })
    //* 需要重新refresh，不然受到pageTransition的影響，scrollTrigger star end的位置會跑掉
    ScrollTrigger.refresh()
    return () => {
      if (ScrollTrigger.getById("elementDarkMode")) {
        ScrollTrigger.getById("elementDarkMode").kill()
      }
    }
  }, [isDark])

  return pug`
    Metadata(title="Home")
    LayoutWrapper.bg-white.overflow-hidden
      Header(link3=true darkmode=isDark)
      Social(darkmode=isDark)
      // ScrollWrapper
      MainContainer
        HomepageLanding
        HomepageProjects
        HomepageExperience
      Footer
  `
}

export default Headline
